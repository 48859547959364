<template>
    <div class="flex align-items-center py-5 px-3">
        <i class="pi pi-fw pi-check mr-2 text-2xl" />
        <p class="m-0 text-lg">Confirmation Component Content via Child Route</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nestedRouteItems: null,
        };
    },
    created() {
        console.log('Confirmation')
        console.log('current page is ' + window.sessionStorage.getItem('currPage'));
        // console.log('matched: ' + this.$route.matched);
        // for(var m in this.$route.matched){
        //     console.log(this.$route.matched[m])
        // }
        console.log(this.$route.query)

        var jsonStr = window.sessionStorage.getItem('breadItems')
        this.nestedRouteItems = JSON.parse(jsonStr);
    },
    methods: {
        gotoPage(to) {
            console.log('now change to ' + to);

            this.nestedRouteItems.push({
                label: to,
                to: '/menu/payment',
            });

            window.sessionStorage.setItem('currPage', to);
            window.sessionStorage.setItem('breadItems', JSON.stringify(this.nestedRouteItems));

            this.$emit('updateData');
            return true;
        },
    },
}
</script>